import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import {
  FormControl,
  FormHelperText,
  Icon,
  Input,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { connect } from 'react-redux'
import { deepEqual, isGrid, getIn } from 'utils'
import { BASE_INFORM_URL } from 'services/constants'
import * as CONSTANTS from './constants'
// import deep from 'deep-diff'
import { isDisabled, validateField, onDoubleClick } from './utils'

const propsToNotUpdateFor = [
  // '_ddiForm',
  'dispatch',
  'dataId',
  'blur',
  'change',
  'meta',
  'onChange',
  'onFocus',
  'focus',
  'getFormState',
  // 'propertyChange',
  'setField',
  'style'
]
// paginate: PropTypes.shape({
//   next: PropTypes.func,
//   prev: PropTypes.func,
// }),

class ConnectedSelectField extends Component {
  static propTypes = {
    displayEmpty: PropTypes.bool,
    initialValue: PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.any
      }),
      PropTypes.string
    ]), // PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    metaKey: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    forwardRef: PropTypes.bool
  }

  static defaultProps = {
    displayEmpty: false,
    initialValue: null,
    metaKey: null,
    forwardRef: false,
    selectValue: 'dataId',
    selectDescription: 'description'
  }
  // componentDidMount() {
  //   // console.log('conn field willmount', this.props)
  //   // console.log('connected select field mounted')
  //   // if (this.props.initialValue) {
  //   //   this.props.setField(this.props.initialValue)
  //   // }
  //   this.id = shortid.generate()
  // }

  shouldComponentUpdate(nextProps) {
    const nextPropsKeys = Object.keys(nextProps)
    const thisPropsKeys = Object.keys(this.props)
    const shouldUpdate =
      nextPropsKeys.length !== thisPropsKeys.length ||
      nextPropsKeys.some(
        prop =>
          //eslint-disable-line
          !~propsToNotUpdateFor.indexOf(prop) &&
          !deepEqual(this.props[prop], nextProps[prop])
      )
    return shouldUpdate
  }

  getRenderedComponent() {
    return this.refs.renderedComponent // eslint-disable-line react/no-string-refs
  }

  id = shortid.generate()

  render() {
    const {
      component,
      displayEmpty,
      mappingComponent,
      values,
      disabled,
      value,
      onChange,
      fullWidth,
      onDoubleClick,
      ...rest
    } = this.props
    // console.log(this.props)
    return (
      <FormControl
        className="mui-select-field"
        /* fullWidth */ style={this.props.style}
        margin={this.props.margin}
        fullWidth={fullWidth}
      >
        {this.props.label && (
          <InputLabel htmlFor={this.id}>{this.props.label}</InputLabel>
        )}
        <Select
          value={value || ''}
          onChange={onChange}
          displayEmpty={displayEmpty}
          disabled={disabled}
          onDoubleClick={onDoubleClick}
          // fullWidth={fullWidth}
          input={
            this.props.label ? (
              <Input name={this.props.label} id={this.props.label} />
            ) : (
              undefined
            )
          }
        >
          {this.props.values.map(item => (
            <MenuItem
              value={item[this.props.selectValue]}
              // key={`${item.dataId || item.recordName || item.primaryText}-${item.description || item.value}`}
              key={`${item[this.props.selectDescription]}-${
                item[this.props.selectValue]
              }`}
              inputProps={{ id: this.id }}
            >
              {item[this.props.selectDescription]}
            </MenuItem>
          ))}
        </Select>
        {this.props.errorText && ( // todo: error location
          <span>
            <FormHelperText style={{ color: 'rgb(217, 83, 79)' }}>
              {this.props.errorText}
            </FormHelperText>
            <div title={rest.errorText}>
              <Icon
                style={{
                  bottom: 20,
                  color: '#d9534f',
                  height: 20,
                  position: 'absolute',
                  right: -25,
                  width: 20,
                  fontSize: 16
                }}
              >
                error_outline
              </Icon>
            </div>
          </span>
        )}
      </FormControl>
    )
    // return <div>{createElement(component, { ...rest }, values.map(mappingComponent))}</div>
  }
}

const connector = connect(
  (state, ownProps) => {
    let {
      metaKey,
      propertyName,
      values,
      // setField,
      getEntity,
      getFormState,
      partialMatchSearch,
      exactMatchSearch,
      findPrev,
      findNext
    } = ownProps

    const formState = getFormState(state)
    let { initialValue } = ownProps
    if (initialValue) {
      // console.log(
      //   initialValue,
      //   ownProps,
      //   CONSTANTS,
      //   initialValue.type === CONSTANTS.DEFAULT_VALUE_TYPES.VALUE,
      // )

      if (initialValue.type) {
        // debugger
        initialValue =
          initialValue.type === CONSTANTS.DEFAULT_VALUE_TYPES.VALUE
            ? initialValue.value
            : getIn(ownProps._ddiForm, initialValue.value)
      }
      if (typeof initialValue === 'function') {
        initialValue = initialValue(formState, state)
      }
    }

    // const dataId = getIn(formState, 'dataId') || ''
    // const focus = ownProps.focus.bind(null, propertyName)
    // const blur = ownProps.blur.bind(null, propertyName)
    // const change = ownProps.change.bind(null, propertyName)

    let { setField } = ownProps // .bind(null, propertyName)

    if (Reflect.has(setField, 'prototype')) {
      setField = setField.bind(null, propertyName)
    }
    let field = getIn(formState, `fields.${propertyName}`) // .toJS()
    // field = (field && field? field.toJS()) || {}
    field = field?.toJS ? field.toJS() : field || {}
    const isFound = getIn(formState, 'isFound') || false
    // const isFiltered = getIn(formState, 'is')
    let meta = getIn(formState, 'meta')
    if (metaKey && meta) {
      meta = getIn(meta, metaKey)
      meta = meta ? meta.toJS() : {}
    } else if (!metaKey || !meta) {
      meta = {}
    }
    meta = ownProps.meta ? ownProps.meta : meta
    meta = meta || {}
    // meta = meta ? meta.toJS() : {}
    const isEntitySuccessComplete =
      getIn(formState, 'isEntitySuccessComplete') || false
    const onChange = (event, index, value) => {
      // console.log(event)
      event = typeof event === 'object' ? event.target.value : event
      // debugger
      setField(event, null, null, null, ownProps.valueKey)
    }
    let onTab
    if (ownProps.onTab) {
      if (typeof ownProps.onTab === 'string') {
        // debugger
        const form = ownProps._ddiForm.childOf
          ? `${ownProps._ddiForm.childOf}.${ownProps._ddiForm.form}`
          : ownProps._ddiForm.form
        const act = getIn(ownProps._ddiForm, ownProps.onTab)

        // onTab = act.bind(null, form, ownProps.propertyName)
        onTab = value => act(ownProps.propertyName, value)
        // console.log(act, onTab)
      }
    }
    const errorText = validateField(field, formState, ownProps)

    if (ownProps.values) {
      if (Array.isArray(ownProps.values)) {
        values = ownProps.values
      } else if (typeof ownProps.values === 'function') {
        values = ownProps.values(formState, state).toJS()
      }
      if (!values || !ownProps.values) {
        values = []
      }
    } else if (field.values) {
      values = field.values
    } else {
      values = []
    }

    const doubleClick = () => onDoubleClick(formState, ownProps)

    const props = {
      _value: ownProps.value, // save value passed in (for checkboxes)
      onBlur: blur,
      // change,
      // dataId,
      initialValue,
      exactMatchSearch,
      findPrev,
      findNext,
      focus,
      getEntity,
      isEntitySuccessComplete,
      isFound,
      meta,
      onChange,
      partialMatchSearch,
      // propertyChange,
      setField,
      onTab,
      errorText,
      disabled: isDisabled(ownProps, field, formState),
      values,
      onDoubleClick: doubleClick
    }
    // props = { ...field, ...props }
    // return props
    if (typeof field === 'string' && ownProps.valueKey) {
      return {
        ...props,
        value: field
      }
    }
    return { ...field, ...props }
  },
  undefined,
  undefined,
  { forwardRef: true }
)
export default connector(ConnectedSelectField)
