import tooltipFieldSagas from 'hoc/TooltipField/sagas'
import ddiFormListeners from 'ddiForm/sagas'
import dashboardSagas from 'pages/Dashboard/sagas'
import indexSearchSagas from 'components/Search/IndexSearch/sagas'
import indexSearchMobileSagas from 'mobile/components/IndexSearchMobile/sagas'
import { isMobileSelector } from 'mobile/selectors'
import layoutSagas from 'pages/Layout/sagas'
import mailListeners, { userMailInitiator } from 'pages/UserMail/sagas'
import { AUTHENTICATION_ERROR, SUBMIT_LOGOUT_USER } from 'auth/constants'
import salesOrderSearchMobileSagas from 'mobile/components/SalesOrderSearch/sagas'
import purchaseOrderSearchMobileSagas from 'mobile/components/PurchaseOrderSearch/sagas'
// import { mailSagas } from 'pages/UserMail/sagas'

import { actionChannel, fork, take, cancel, select } from 'redux-saga/effects'

import {
  purchaseOrderExactMatchSearchListener
  // dropDownOpenListener as purchaseOrderInquiryDropDownListener
} from 'pages/PurchaseOrderInquiry/sagas'
import {
  salesOrderExactMatchListener
  // dropDownOpenListener as salesOrderInquiryDropDownListener
} from 'pages/SalesOrderInquiry/sagas'
import arInvoiceSearchListeners from 'components/Search/ARInvoiceSearch/sagas'
import binSearchListeners from 'components/Search/BinSearch/sagas'
import secureSearchListeners from 'components/Search/SecureSearch/sagas'
import sendInfoSearchListeners from 'components/Search/SendInfoSearch/sagas'
import sendDocumentSagas from 'components/SendDocument/sagas'
import documentSearchListeners from 'components/Search/DocumentSearch/sagas'
import serialNumberSearchListeners from 'components/Search/SerialNumberSearch/sagas'
import receiptNumberInquirySagas from 'components/Search/ReceiptNumberSearch/components/ReceiptNumberDropdown/sagas'

const sagas = [
  userMailInitiator,
  ddiFormListeners,
  arInvoiceSearchListeners,
  dashboardSagas,
  // indexSearchSagas,
  salesOrderExactMatchListener,
  // salesOrderInquiryDropDownListener,
  purchaseOrderExactMatchSearchListener,
  // purchaseOrderInquiryDropDownListener,
  tooltipFieldSagas,

  mailListeners,
  // ...mailSagas,
  layoutSagas,
  secureSearchListeners,
  binSearchListeners,
  sendInfoSearchListeners,
  sendDocumentSagas,
  documentSearchListeners,
  serialNumberSearchListeners,
  receiptNumberInquirySagas
]
let task
export default function* authListener() {
  const channel = yield actionChannel('INITIATE_AUTHENTICATED_LISTENERS')

  while (true) {
    yield take(channel)
    // debugger
    let localSagas = [...sagas]

    const isMobile = yield select(isMobileSelector)

    localSagas = localSagas.concat(
      isMobile
        ? [
            indexSearchMobileSagas,
            salesOrderSearchMobileSagas,
            purchaseOrderSearchMobileSagas
          ]
        : indexSearchSagas
    )

    if (task) {
      yield cancel(task)
    }

    task = yield fork(authTaskRunner, localSagas)

    yield take([SUBMIT_LOGOUT_USER.REQUEST, AUTHENTICATION_ERROR])
  }
}

export function* authTaskRunner(tasks = sagas) {
  for (const t of tasks) {
    yield fork(t)
  }
}
