import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import memoize from 'memoize-one'
import { difference, noop, plainDeepEqual } from 'utils'
import IconButton from '@material-ui/core/IconButton'
import { Icon, Popover, TextField } from '@material-ui/core'

import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { intersection, isEqual, uniq, debounce } from 'lodash'
import shortid from 'shortid'
import { ddiFormStyles } from 'ddiForm/ddiFormStyles'
import DropdownGrid from './components/DropdownGrid'
import './styles/css-mod-ignore.scss'

const comma = ','
const tab = 'Tab'
const blur = 'blur'

const nonEmptyString = x => x !== ''

const split = str => str.split(comma).filter(nonEmptyString)

const getGridHeight = memoize(data => {
  let height = 200
  if (data && Array.isArray(data) && data.length) {
    height = data.length * 30 + 32 + 56
  }

  return height
}, isEqual)

const propsToUpdateFor = ['value']

/* eslint-disable no-nested-ternary */
const mapStateToProps = (state, ownProps) => ({
  gridOptions: {
    ...ownProps.gridOptions,
    columnDefs: [
      {
        cellClass: 'is-checkbox-selection flex-centered search-dropdown-cell',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: 50,
          padding: 0
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerClass: 'is-checkbox-selection no-padding flex-centered',
        headerStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        },
        headerName: '',
        suppressSorting: true,
        width: 50,
        maxWidth: 50,
        minWidth: 50
      },
      ...ownProps.gridOptions.columnDefs
    ]
  },
  rowData:
    ownProps.rowDataSelector && !ownProps.rowData
      ? ownProps.rowDataSelector(state)
      : typeof ownProps.rowData === 'function'
      ? ownProps.rowData()
      : ownProps.rowData
})

const makeSelectable = (rowData = [], initialValue) => {
  let initialList = []
  if (initialValue) {
    initialList = initialList.concat(split(initialValue))
  }

  return rowData.map(x => ({
    ...x,
    isSelected: intersection(initialList, Object.values(x)).length > 0
  }))
}

class SearchDropdown extends Component {
  static propTypes = {
    disableToolTip: PropTypes.bool,
    enableRowClickSelection: PropTypes.bool,
    floatingLabelFocusStyle: PropTypes.object,
    floatingLabelShrinkStyle: PropTypes.object,
    floatingLabelStyle: PropTypes.object,
    floatingLabelText: PropTypes.string,
    fullWidth: PropTypes.bool,
    gridOptions: PropTypes.object.isRequired,
    height: PropTypes.number,
    hintStyle: PropTypes.object,
    hintText: PropTypes.string,
    id: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    inputLabelProps: PropTypes.object,
    inputStyle: PropTypes.object,
    isMobile: PropTypes.bool,
    onCloseCb: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onTab: PropTypes.func,
    placeholder: PropTypes.string,
    rowData: PropTypes.array.isRequired,
    rowSelection: PropTypes.string,
    sortValue: PropTypes.bool,
    style: PropTypes.object,
    wrapperMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  static defaultProps = {
    disableToolTip: false,
    enableRowClickSelection: false,
    floatingLabelFocusStyle: ddiFormStyles.selectField.floatingLabelFocusStyle,
    floatingLabelShrinkStyle:
      ddiFormStyles.selectField.floatingLabelShrinkStyle,
    floatingLabelStyle: {
      color: '#517b9c',
      fontSize: 12,
      fontWeight: 'bold',
      top: 24
    },
    floatingLabelText: undefined,
    fullWidth: false,
    gridOptions: {},
    height: 400,
    hintStyle: ddiFormStyles.textField.hintStyle,
    hintText: undefined,
    id: null,
    initialValue: undefined,
    inputLabelProps: null,
    inputStyle: ddiFormStyles.textField.inputStyle,
    isMobile: false,
    onCloseCb: noop,
    onTab: noop,
    placeholder: undefined,
    rowSelection: 'multiple',
    sortValue: false,
    style: ddiFormStyles.selectField.style,
    width: 300,
    wrapperMargin: '5px 0',
    onDoubleClick: noop
  }

  constructor(props) {
    super(props)

    this.onTab = debounce(this.onTab, 1000)
    this.state = {
      gridKey: shortid.generate(),
      gridOptions: {
        ...props.gridOptions,
        className: props.gridOptions.className
          ? props.gridOptions.className
          : 'ag-theme-balham',
        onGridReady: ({ api }) =>
          api.forEachNode(node => {
            if (node.data.isSelected) node.setSelected(true)
          })
      },
      id: props.id || shortid.generate(),
      isDirty: false,
      isOpen: false,
      rowData: makeSelectable(props.rowData, props.initialValue),
      value: props.value || props.initialValue
    }
  }

  componentDidMount() {
    this.anchorEl = findDOMNode(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps?.form &&
      nextProps?.ConnectedField &&
      nextProps.value !== prevState.value &&
      !prevState.isOpen &&
      Array.isArray(nextProps.rowData)
    ) {
      /* this importantly fixes an issue for when users cancel edit (or change entities on a given screen) */
      return {
        gridKey: shortid.generate(),
        rowData: makeSelectable(nextProps.rowData, nextProps.value),
        lastValue: nextProps.value,
        value: nextProps.value
      }
    }

    if (nextProps.value !== prevState.lastValue) {
      return {
        lastValue: nextProps.value,
        value: nextProps.value
      }
    }

    return null
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.isOpen) {
      if (this.state.isOpen !== nextState.isOpen) {
        return true
      }

      return Object.keys(nextProps).some(
        prop =>
          propsToUpdateFor.includes(prop) &&
          !plainDeepEqual(this.props[prop], nextProps[prop])
      )
    }

    return true
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.focus && this.state.focus && this.input) {
      // kinda hacky.. need a better way to figure this out?
      setTimeout(() => this.input.select(), 50)
    }
  }

  onBlur = event => {
    this.onKeyDown(event)
  }

  onChange = event => {
    this.setState({ isDirty: true, value: event.target.value })
  }

  // onFocus = event => event.target.select()

  onKeyDown = event => {
    const { isDirty, rowData } = this.state
    const { identifier } = this.props
    if (isDirty && (event.key === tab || event.type === blur)) {
      event.preventDefault()
      const val = event.target.value
      let valList = split(val)
      valList = valList.filter(
        x => ~rowData.findIndex(y => y[identifier] === x)
      )
      this.setState(
        {
          isDirty: false,
          value: this.props.sortValue
            ? valList.sort().join(comma)
            : valList.join(comma)
        },
        () => this.onTab(this.state.value)
      )
    }
  }

  onKeyUp = event => {
    const { identifier } = this.props
    const val = event.target.value
    const valList = split(val)
    const uniqVals = uniq(valList)

    this.setState(prevState => {
      const newRowData = prevState.rowData.map(row => ({
        ...row,
        isSelected: Boolean(~uniqVals.indexOf(row[identifier]))
      }))

      return {
        ...prevState,
        rowData: newRowData
      }
    })
  }

  onTab = val => {
    if (this.props.setField) {
      this.props.setField(val)
    } else {
      this.props.onTab(val)
    }

    if (this.input && this.input.blur) {
      this.input.blur()
    }
  }

  onRowSelected = ({ node }) => {
    const { rowIndex } = node
    const { identifier, onTab } = this.props

    let value = ''
    this.setState(
      prevState => {
        value = prevState.value || ''
        let valList = split(value)

        const id = node.data[identifier]
        const isSelected = node.isSelected()
        const idx = valList.indexOf(id)

        if (isSelected) {
          if (!~idx) {
            valList = valList.concat(id)
          }
        } else {
          valList = [...valList.slice(0, idx), ...valList.slice(idx + 1)]
        }

        value = this.props.sortValue
          ? valList.sort().join(comma)
          : valList.join(comma)

        return {
          ...prevState,
          rowData: [
            ...prevState.rowData.slice(0, rowIndex),
            {
              ...prevState.rowData[rowIndex],
              isSelected
            },
            ...prevState.rowData.slice(rowIndex + 1)
          ],
          value,
          valueChanged: prevState.value !== value
        }
      },
      () => this.onTab(value)
    )
  }

  onSelectionChanged = () => {
    if (this.grid && this.grid.api) {
      const { identifier, onTab } = this.props
      const value = this.props.sortValue
        ? this.grid.api
            .getSelectedRows()
            .map(row => row[identifier])
            .sort()
            .join(comma)
        : this.grid.api
            .getSelectedRows()
            .map(row => row[identifier])
            .join(comma)

      // this.setState({ value })
      this.setState({ value }, () => {
        // debugger
        onTab(value)
      })
    }
  }

  select = () => {
    if (this.input) {
      //  this.anchor.focus()
      //  setTimeout(() => this.anchor.select(), 1)
      this.setState({ focus: true }) // this.anchor.select()
    }
  }

  makeTooltip = () => {
    const { value } = this.state
    const { disableToolTip } = this.props
    let returnVal = null

    if (disableToolTip) return null

    if (typeof value === 'string' && value) {
      returnVal = (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {value.split(comma).map((v, k, arr) => {
            const len = arr.length
            const c = len > 1 && k + 1 < len ? comma : ''
            return <li key={v}>{`${v}${c}`}</li>
          })}
        </ul>
      )
    }
    return returnVal
  }

  inputRef = c => {
    this.input = c
    if (this.props.inputRef && typeof this.props.inputRef === 'function') {
      this.props.inputRef(c)
    }
    // else if (this.props.inputRef && typeof this.props.inputRef === 'object') {
    //   return this.props.inputRef
    // }
    // return undefined
  }
  // ref = React.createRef()

  getReference = () =>
    this.props.inputRef && typeof this.props.inputRef === 'object'
      ? this.props.inputRef
      : this.inputRef

  positionFilterEditor = params => {
    // console.log('positionFilterEditor', params)
    params.ePopup.style.zIndex = 1302
    params.ePopup.style.paddingBottom = '20px'
  }

  onCloseCb = () => {
    if (this?.props?.onCloseCb && typeof this.props.onCloseCb) {
      this.props.onCloseCb(this.state.value)
    }
  }

  render() {
    const {
      disabled,
      enableRowClickSelection,
      floatingLabelFocusStyle,
      floatingLabelShrinkStyle,
      floatingLabelStyle,
      floatingLabelText,
      fullWidth,
      height,
      hintStyle,
      hintText,
      inputLabelProps,
      inputStyle,
      placeholder,
      rowSelection,
      style,
      width,
      wrapperMargin,
      onDoubleClick,
      isMobile
    } = this.props

    const { gridOptions, id, rowData } = this.state

    // debugger
    const reference = this.getReference()

    const gridHeight = getGridHeight(rowData)

    return (
      <div style={{ position: 'relative', margin: wrapperMargin }}>
        {!isMobile ? (
          <ReactTooltip
            delayShow={300}
            getContent={this.makeTooltip}
            id={id}
            place="right"
          />
        ) : null}
        <TextField
          label={floatingLabelText}
          fullWidth={fullWidth}
          // hintText={hintText}
          placeholder={placeholder}
          onBlur={this.onBlur}
          onChange={this.onChange}
          // onFocus={this.onFocus}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          onDoubleClick={onDoubleClick}
          inputRef={reference}
          value={this.state.value || ''}
          disabled={disabled}
          InputLabelProps={inputLabelProps}
        />
        <IconButton
          onClick={() => this.setState({ isOpen: true })}
          style={{
            background: '#fff',
            paddingBottom: 5,
            position: 'absolute',
            right: -10,
            top: floatingLabelText ? 5 : -10
          }}
          data-tip
          data-for={id}
          data-class="inform-tooltip"
          data-effect="solid"
          tabIndex={-1}
          disabled={disabled}
        >
          <Icon style={{ color: '#444' }}>arrow_drop_down</Icon>
        </IconButton>
        <Popover
          anchorEl={this.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={this.state.isOpen}
          onClose={() =>
            this.setState({ isOpen: false }, () => this.onCloseCb())
          }
          style={{ width }}
        >
          <div
            id="search-dropdown-inner-wrapper"
            style={{
              width: 300,
              paddingRight: 15,
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <DropdownGrid
              key={this.state.gridKey}
              gridOptions={gridOptions}
              gridRef={c => (this.grid = c)}
              rowSelection={rowSelection}
              onRowSelected={this.onRowSelected}
              onSelectionChanged={this.onSelectionChanged}
              rowData={rowData}
              suppressRowClickSelection={!enableRowClickSelection}
              gridHeight={gridHeight}
              postProcessPopup={this.positionFilterEditor}
              value={this.state.value}
              isOpen={this.state.isOpen}
            />
          </div>
        </Popover>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(SearchDropdown)
