import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import {
  PARTIAL_MATCH_SEARCH_URL,
  EXACT_MATCH_SEARCH_URL,
  FIND_NEXT_SEARCH_URL,
  FIND_PREV_SEARCH_URL,
  GET_TOOLTIP_URL,
  GET_SEARCH_FILTER_URL
} from './IndexSearch/constants'

import {
  BIN_PARTIAL_MATCH_SEARCH_URL,
  BIN_EXACT_MATCH_SEARCH_URL
} from './BinSearch/constants'

import {
  CHECK_PARTIAL_MATCH_SEARCH_URL,
  CHECK_EXACT_MATCH_SEARCH_URL
} from './CheckSearch/constants'

import {
  SERIAL_NUMBER_PARTIAL_MATCH_SEARCH_URL,
  SERIAL_NUMBER_EXACT_MATCH_SEARCH_URL
} from './SerialNumberSearch/constants'

import {
  SECURE_PARTIAL_MATCH_SEARCH_URL,
  SECURE_EXACT_MATCH_SEARCH_URL
} from './SecureSearch/constants'

import {
  SEND_DOCUMENT_CONTACTS_SEARCH_URL,
  SEND_DOCUMENT_EXACT_MATCH_SEARCH_URL,
  SEND_DOCUMENT_PARTIAL_MATCH_SEARCH_URL
} from './SendInfoSearch/constants'

import { AR_INVOICE_EXACT_MATCH_URL } from './ARInvoiceSearch/constants'

export const exactMatchSearch = ({
  allowSpecialProducts,
  indexSearchType,
  keyword,
  filters = null,
  pageNumber = 1,
  moreInfo = false,
  includeParent = false,
  isRecordName = false,
  allowInvalidValues = false,
  parentId,
  parentType = '',
  resultsMaxLimit,
  exactMatchOnly = false
}) => {
  const options = createApiOptions({
    body: {
      indexSearchType,
      keyword,
      filters,
      pageNumber,
      moreInfo,
      includeParent,
      isRecordName,
      allowInvalidValues,
      parentId,
      parentType,
      resultsMaxLimit,
      allowSpecialProducts,
      fromSales: true,
      exactMatchOnly
    },
    method: 'post'
  })
  return callFetch(EXACT_MATCH_SEARCH_URL, options)
}

export const partialMatchSearch = ({
  indexSearchType,
  keyword = null,
  filters = null,
  pageNumber,
  parentId = null,
  isFiltered = false,
  parentType = null,
  resultsMaxLimit,
  ...rest
}) => {
  const options = createApiOptions({
    body: {
      indexSearchType,
      keyword,
      filters,
      pageNumber,
      parentId,
      parentType,
      filtered: isFiltered,
      resultsMaxLimit,
      ...rest
    },
    method: 'post'
  })

  return callFetch(PARTIAL_MATCH_SEARCH_URL, options)
}

export const getPrevExactMatch = ({ recordName, indexSearchType }) => {
  const options = createApiOptions({
    body: { indexSearchType, recordName },
    method: 'post'
  })
  return callFetch(FIND_PREV_SEARCH_URL, options)
}

export const getNextExactMatch = ({ recordName, indexSearchType }) => {
  const options = createApiOptions({
    body: { indexSearchType, recordName },
    method: 'post'
  })
  return callFetch(FIND_NEXT_SEARCH_URL, options)
}

export const getIndexSearchTooltip = ({ recordName, indexSearchType }) => {
  const options = createApiOptions({
    body: { indexSearchType, recordName },
    method: 'post'
  })
  return callFetch(GET_TOOLTIP_URL, options)
}

export const getSearchFilters = ({ indexSearchType }) => {
  const options = createApiOptions({
    body: { indexSearchType },
    method: 'post'
  })
  return callFetch(GET_SEARCH_FILTER_URL, options)
}

export const arInvoiceSearchExactMatch = ({ keyword }) => {
  const options = createApiOptions({
    body: { keyword, moreInfo: true },
    method: 'post'
  })
  return callFetch(AR_INVOICE_EXACT_MATCH_URL, options)
}

export const binPartialMatchSearch = ({
  includeSystemBins,
  isFromBin,
  keyword,
  onHandOnly,
  productId,
  propertyName,
  uom,
  warehouseId
}) => {
  const options = createApiOptions({
    body: {
      includeSystemBins,
      isFromBin,
      keyword,
      onHandOnly,
      productId,
      uomId: uom,
      warehouseId
    },
    method: 'post'
  })

  return callFetch(BIN_PARTIAL_MATCH_SEARCH_URL, options)
}

export const binExactMatchSearch = ({
  includeSystemBins,
  isFromBin,
  keyword,
  onHandOnly,
  productId,
  propertyName,
  uom,
  warehouseId
}) => {
  const options = createApiOptions({
    body: {
      includeSystemBins,
      isFromBin,
      keyword,
      onHandOnly,
      productId,
      uomId: uom,
      warehouseId
    },
    method: 'post'
  })
  return callFetch(BIN_EXACT_MATCH_SEARCH_URL, options)
}

export const secureExactMatchSearch = ({
  keyword,
  // pageNumber = 1,
  moreInfo = false
}) => {
  const options = createApiOptions({
    body: {
      keyword,
      // pageNumber,
      moreInfo
    },
    method: 'post'
  })
  return callFetch(SECURE_EXACT_MATCH_SEARCH_URL, options)
}

export const securePartialMatchSearch = ({
  indexSearchType,
  keyword = null,
  filters = null,
  pageNumber,
  parentId = null,
  isFiltered = false,
  parentType = null
}) => {
  const options = createApiOptions({
    body: {
      indexSearchType,
      keyword,
      filters,
      pageNumber,
      parentId,
      parentType,
      filtered: isFiltered
    },
    method: 'post'
  })

  return callFetch(SECURE_PARTIAL_MATCH_SEARCH_URL, options)
}

export const sendInfoExactMatchSearch = ({
  keyword,
  // pageNumber = 1,
  sendType = 'email'
}) => {
  const options = createApiOptions({
    body: {
      keyword,
      // pageNumber,
      sendType
    },
    method: 'post'
  })
  return callFetch(SEND_DOCUMENT_EXACT_MATCH_SEARCH_URL, options)
}

export const sendInfoPartialMatchSearch = ({
  // sendType = 'email',
  ...rest
}) => {
  const options = createApiOptions({
    body: {
      //  sendType,
      ...rest
    },
    method: 'post'
  })

  return callFetch(SEND_DOCUMENT_PARTIAL_MATCH_SEARCH_URL, options)
}

export const sendInfoContactsSearch = ({ ...rest }) => {
  const options = createApiOptions({
    body: {
      ...rest
    },
    method: 'post'
  })

  return callFetch(SEND_DOCUMENT_CONTACTS_SEARCH_URL, options)
}

export const documentPartialMatchSearch = ({
  dataId,
  keyword = null,
  form,
  documentType = 'image',
  parentType,
  parentId
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      keyword,
      parentType,
      parentId
    },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${form}/search/partialmatch/${documentType}`,
    options
  )
}

export const documentExactMatchSearch = ({
  dataId,
  keyword = null,
  form,
  documentType = 'image',
  parentType,
  parentId
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      keyword,
      parentType,
      parentId
    },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${form}/search/exactmatch/${documentType}`,
    options
  )
}

export const documentUpload = ({
  dataId,
  templateKey,
  fileName,
  content,
  form,
  documentType,
  parentType,
  parentId
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      templateKey,
      fileName,
      content,
      parentType,
      parentId
    },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${form}/upload/${documentType}`,
    options
  )
}

export const getImageTooltip = ({ form, fileName }) => {
  const options = createApiOptions({
    body: {
      fileName
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/download/image`, options)
}

export const documentDownload = ({
  form,
  fileName,
  documentType = 'image'
}) => {
  const options = createApiOptions({
    body: {
      fileName
    },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${form}/download/${documentType}`,
    options
  )
}

export const serialNumberPartialMatchSearch = ({
  keyword,
  customerId,
  productId,
  propertyName,
  uomId,
  warehouseId,
  ...rest
}) => {
  const options = createApiOptions({
    body: {
      keyword,
      customerId,
      productId,
      uomId,
      warehouseId,
      ...rest
    },
    method: 'post'
  })

  return callFetch(SERIAL_NUMBER_PARTIAL_MATCH_SEARCH_URL, options)
}

export const serialNumberExactMatchSearch = ({
  keyword,
  customerId,
  productId,
  propertyName,
  uomId,
  warehouseId,
  ...rest
}) => {
  const options = createApiOptions({
    body: {
      keyword,
      customerId,
      productId,
      uomId,
      warehouseId,
      ...rest
    },
    method: 'post'
  })
  return callFetch(SERIAL_NUMBER_EXACT_MATCH_SEARCH_URL, options)
}

export const checkPartialMatchSearch = ({ keyword, customerId }) => {
  const options = createApiOptions({
    body: {
      keyword,
      customerId
    },
    method: 'post'
  })

  return callFetch(CHECK_PARTIAL_MATCH_SEARCH_URL, options)
}

export const checkExactMatchSearch = ({ keyword, customerId }) => {
  const options = createApiOptions({
    body: {
      keyword,
      customerId
    },
    method: 'post'
  })
  return callFetch(CHECK_EXACT_MATCH_SEARCH_URL, options)
}
