import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import shouldUpdate from 'hoc/shouldUpdate'
import { once } from 'lodash'
import { EventEmitter } from 'fbemitter'
import memoize from 'memoize-one'
import { deepEqual } from 'utils'
import PrimaryTabs from './PrimaryTabs'
import SecondaryTabs from './SecondaryTabs'
import ContentArea from './ContentArea'
import SearchArea from './SearchArea'
import Footer from './Footer'
import { withDDIForm } from '../DDIFormContext'
import classStyles from './styles.scss'
import './css-mod-ignore.scss'

const whitelist = ['title', 'isEditing', 'hasRecord']
const styles = {
  body: {
    flexGrow: 1,
    minHeight: '2em',
    overflow: 'auto',
    display: 'flex',
    padding: '0.5rem 0'
  },
  // container: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   flexWrap: 'nowrap'
  //   // height: '100%',
  //   // position: 'sticky',
  //   // width: '100%'
  //   // flex: 1
  // },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    position: 'sticky',
    width: '100%'
  },
  footer: { flexShrink: 0 },
  header: {
    flexShrink: 0,
    marginBottom: 5
  },
  sticky: {
    boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.35)',
    position: 'sticky',
    top: 0,
    zIndex: 3
  }
}

export class MasterScreenWrapper extends Component {
  static propTypes = {
    changePrimaryTab: PropTypes.func.isRequired,
    changeSecondaryTab: PropTypes.func.isRequired,
    form: PropTypes.string,
    masterOptions: PropTypes.object
  }

  static defaultProps = {
    form: null,
    masterOptions: null
  }

  constructor(...args) {
    super(...args)
    this.contentRef = createRef()
    const { masterOptions } = this.props
    this.Header = masterOptions.headerComponent
      ? masterOptions.headerComponent
      : SearchArea
    this.Footer = masterOptions.footerComponent
      ? masterOptions.footerComponent
      : Footer
    // this.state = {
    //   searchAreaTouched: false
    // }
    this.emitter = new EventEmitter()
  }

  // componentDidUpdate(prevProps, prevState) {
  //   Object.keys(this.props).forEach(prop => {
  //     if (!deepEqual(this.props[prop], prevProps[prop])) {
  //       console.info(
  //         'master index',
  //         prop,
  //         'changed',
  //         prevProps[prop],
  //         '==>',
  //         this.props[prop]
  //       )
  //     }
  //   })
  // }

  // changePrimaryTab = val => this.props.changePrimaryTab(val)
  changeFormTab = (a, b) => {
    // debugger
    this.props.tryChangeFormTab(a, b)
  }
  //
  // touchSearchArea = once(() => this.setState({ searchAreaTouched: true }))

  touchSearchArea = once(() => {
    this.emitter.emit('touched', { form: this.props.form })
  })

  render() {
    console.log('master rendered', this.props.form)
    const { hideSecondaryTabs } = this.props.masterOptions
    return (
      <div ref={c => (this.container = c)} style={styles.container}>
        <div style={styles.header}>
          <this.Header {...this.props} touchSearchArea={this.touchSearchArea} />
          <PrimaryTabs
            form={this.props.form}
            // changePrimaryTab={this.changePrimaryTab}
            changeFormTab={this.changeFormTab}
          />

          {!hideSecondaryTabs && (
            <SecondaryTabs
              form={this.props.form}
              // changeSecondaryTab={this.props.changeSecondaryTab}
              changeFormTab={this.changeFormTab}
            />
          )}
        </div>
        <div style={styles.body}>
          <ContentArea
            form={this.props.form}
            ref={this.contentRef}
            emitter={this.emitter}
            // isEditing={this.props.isEditing}
            // hasRecord={this.props.hasRecord}
            // dispatch={this.props.dispatch}
          />
        </div>
        <div style={styles.footer}>
          <this.Footer
            {...this.props}
            contentRef={this.contentRef}
            ref={c => (this.footer = c)}
          />
        </div>
      </div>
    )
  }
}
// export default CSSModules(MasterScreenWrapper, classStyles, {
//   allowMultiple: true
// })
// export default MasterScreenWrapper
// export default shouldUpdate({ blacklist })(MasterScreenWrapper)
export default shouldUpdate({ whitelist })(MasterScreenWrapper)
