const isForeignCountry = (country = '', isCanadianCompany = false) => {
  if (!country || country === '' || isCanadianCompany) {
    return true
  }

  if (
    (country !== '' &&
      country !== null &&
      !country?.match(
        /UNITED STATES|UNITED STATES OF AMERICA|US|USA|U\.S\.A\.|U\.S\./gi
      )) ||
    isCanadianCompany
  ) {
    return true
  }

  return false
}

export default isForeignCountry
