import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch as MuiToggle, FormControlLabel } from '@material-ui/core'

const defaultCursor = { cursor: 'default' }
export default class Toggle extends Component {
  static propTypes = {
    className: PropTypes.string,
    dataId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isFound: PropTypes.bool.isRequired,
    labelStyle: PropTypes.object,
    propertyName: PropTypes.string.isRequired,
    value: PropTypes.any
  }

  static defaultProps = {
    className: '',
    labelStyle: {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: !!nextProps.value }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state = { value: props.initialValue ? props.initialValue : false }
  }

  getToggle = () => (
    <MuiToggle
      checked={this.state.value}
      id={this.props.id}
      value={this.state.value}
      onChange={this.handleChange}
      disabled={this.props.disabled}
    />
  )

  handleChange = event => {
    const { checked } = event.target
    this.setState({ value: checked }, () =>
      this.props.setField(checked, null, null, null, this.props.valueKey)
    )
  }

  render() {
    const { className, label, labelStyle } = this.props

    return (
      <span onDoubleClick={this.props.onDoubleClick} style={defaultCursor}>
        {label ? (
          <FormControlLabel
            control={this.getToggle()}
            label={label}
            style={labelStyle}
            className={className}
          />
        ) : (
          this.getToggle()
        )}
      </span>
    )
  }
}
