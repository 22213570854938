import createAsyncRequestTypes from 'utils/createAsyncRequestTypes'

const main = '@@main/'
export const OPEN_SCREEN = `${main}OPEN_SCREEN`
export const NEWS_FEED = createAsyncRequestTypes(`${main}NEWS_FEED`)
export const SHOW_BRANCH_MODAL = `${main}SHOW_BRANCH_MODAL`
export const SHOW_REGISTER_MODAL = `${main}SHOW_REGISTER_MODAL`

export const TOGGLE_NAV = `${main}TOGGLE_NAV`
export const TOGGLE_FAST_CLICK = createAsyncRequestTypes(
  `${main}TOGGLE_FAST_CLICK`
)
export const TOGGLE_NEWS_FEED = `${main}TOGGLE_NEWS_FEED`
export const TRY_RESET_FAST_CLICK = `${main}TRY_RESET_FAST_CLICK`
export const RESET_FAST_CLICK = createAsyncRequestTypes(
  `${main}RESET_FAST_CLICK`
)
export const LINK_TO_SCREEN = `${main}LINK_TO_SCREEN`
export const OPEN_EXTERNAL_URL = `${main}OPEN_EXTERNAL_URL`
export const TRY_OPEN_MAIL = `${main}TRY_OPEN_MAIL`
export const RESET_ITEM_SELECTOR = `${main}RESET_ITEM_SELECTOR`

export const SELECT_CARD_TERMINAL = `${main}SELECT_CARD_TERMINAL`

export const TRY_REFRESH_COMPANY_MASTER = `${main}TRY_REFRESH_COMPANY_MASTER`
export const TRY_OPEN_SCREEN = `${main}TRY_OPEN_SCREEN`
export const REFRESH_COMPANY_MASTER = createAsyncRequestTypes(
  `${main}REFRESH_COMPANY_MASTER`
)

export const TOGGLE_MAIL = createAsyncRequestTypes(`${main}TOGGLE_MAIL`)
export const TOGGLE_MFA = createAsyncRequestTypes(`${main}TOGGLE_MFA`)
