import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import {
  OPEN_EXTERNAL_URL,
  OPEN_SCREEN,
  NEWS_FEED,
  RESET_FAST_CLICK,
  SHOW_BRANCH_MODAL,
  SHOW_REGISTER_MODAL,
  TOGGLE_FAST_CLICK,
  TOGGLE_NAV,
  TOGGLE_NEWS_FEED,
  TRY_RESET_FAST_CLICK,
  LINK_TO_SCREEN,
  TRY_OPEN_MAIL,
  RESET_ITEM_SELECTOR,
  SELECT_CARD_TERMINAL,
  TRY_REFRESH_COMPANY_MASTER,
  REFRESH_COMPANY_MASTER,
  TRY_OPEN_SCREEN,
  TOGGLE_MAIL,
  TOGGLE_MFA
} from './constants'

export const toggleMail = createAsyncFormAction(TOGGLE_MAIL)
// export const toggleMailSuccess = (...args) => createAction(TOGGLE_MAIL, ...args)
export const newsFeed = createAsyncAction(NEWS_FEED)
export const toggleFastClick = createAsyncAction(TOGGLE_FAST_CLICK)
export const resetFastClick = createAsyncAction(RESET_FAST_CLICK)
// export const openScreen = (name, image = null) => createAction(OPEN_SCREEN, { image, name })
export const openScreen = (...args) => {
  return createAction(TRY_OPEN_SCREEN, ...args)
}
export const openScreenAttempt = payload =>
  createAction(OPEN_SCREEN, payload, null, false, {
    withAccess: true,
    apiRequest: true,
    thunk: true
  })

// export const openScreen = name => { console.log(name); return createAction(OPEN_SCREEN, {  name }) }
export const showBranchModal = () => createAction(SHOW_BRANCH_MODAL)
export const showRegisterModal = () => createAction(SHOW_REGISTER_MODAL)
export const toggleNav = flag => createAction(TOGGLE_NAV, { flag })
export const toggleNewsFeed = () => createAction(TOGGLE_NEWS_FEED)
export const tryResetFastClick = () => createAction(TRY_RESET_FAST_CLICK)
// export const toggleFastClick = () => createAction(TOGGLE_FAST_CLICK)
// export const linkToScreen = (form = null, screenName, args) =>
//   createAction(LINK_TO_SCREEN, { screenName, ...args }, form)

export const linkToScreen = (form = null, screenName, args) => {
  if (typeof screenName === 'object') {
    args = screenName
    screenName = null
  }
  return createAction(LINK_TO_SCREEN, { screenName, ...args }, form)
}

export const openExternalLink = (form = null, url) =>
  createAction(OPEN_EXTERNAL_URL, { url }, form)
export const tryOpenMail = () =>
  createAction(TRY_OPEN_MAIL, {}, null, null, { withAccess: true })
export const resetItemSelector = () => createAction(RESET_ITEM_SELECTOR)
export const selectCardTerminal = () =>
  createAction(SELECT_CARD_TERMINAL, {}, null, null, { withAccess: true })

export const tryRefreshCompanyMaster = () =>
  createAction(TRY_REFRESH_COMPANY_MASTER)
export const refreshCompanyMaster = createAsyncAction(REFRESH_COMPANY_MASTER)
export const toggleMfa = createAsyncFormAction(TOGGLE_MFA)
